import LogOut from "layouts/authentication/logout";
import React, { Suspense } from "react";
const SignIn = React.lazy(() => import("layouts/authentication/sign-in"));
const NewSkillCategory = React.lazy(() => import("layouts/skillCategory/newSkillCategory"));
const EditSkillCategory = React.lazy(() => import("layouts/skillCategory/editSkillCategory"));

// Skill categories
const ListSkill = React.lazy(() => import("layouts/skill/listSkill"));
const NewSkill = React.lazy(() => import("layouts/skill/newSkill"));
const EditSkill = React.lazy(() => import("layouts/skill/editSkill"));
const EditMain = React.lazy(() => import("layouts/skill/editMain"));

//platform fees
// import PlatformFees from "layouts/platformFee/newPlatformFee";

// User
const UserSearch = React.lazy(() => import("layouts/user/search"));
const UserProfile = React.lazy(() => import("layouts/user/profile"));
// Notification

// Dashboard
const Dashboard = React.lazy(() => import("layouts/dashboard"));
// Withdrawal
const ListWithdrawal = React.lazy(() => import("layouts/withdrawal/listWithdrawal"));
const WithdrawalDetails = React.lazy(() => import("layouts/withdrawal/withdrawalDetails"));
// Application
import ListApplication from "layouts/application/listApplication";
const ApplicationDetails = React.lazy(() => import("layouts/application/applicationDetails"));

// IdentifyDocuments
const IdentifyDocuments = React.lazy(() => import("layouts/identifyDocuments/listIdentifyDocuments"));
const IdentifyDocumentsDetails = React.lazy(() => import("layouts/identifyDocuments/identifyDocumentDetails"));
const ListBlog = React.lazy(() => import("layouts/blogs/listBlog"));
const AddBlog = React.lazy(() => import("layouts/blogs/addBlog"));
const UpdateBlog = React.lazy(() => import("layouts/blogs/editBlog"));
// Banned users
const ListBannedUsers = React.lazy(() => import("layouts/user/banned"));
// finance
const FinanceStatistics = React.lazy(() => import("layouts/finance/statistics"));
// @mui icons
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BlockIcon from "@mui/icons-material/Block";
import ConstructionIcon from "@mui/icons-material/Construction";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import HandymanIcon from "@mui/icons-material/Handyman";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import Icon from "@mui/material/Icon";
import Jobs from "layouts/Jobs";
import ActiveContracts from "layouts/Jobs/ActiveContracts";
import JobDetails from "layouts/Jobs/JobDetails";
import UploadedImages from "layouts/blogs/UploadedImages";
import PayoutDetails from "layouts/finance/Payoutdetails";
import SendNotification from "layouts/notifications/sendnotification.js";
import PlatformFee from "layouts/platformFee";
import AddUser from "layouts/settings/AddUser";
import DisableUser from "layouts/settings/DisableUser";
import EditUser from "layouts/settings/EditUser";
import PasswordChange from "layouts/settings/PasswordChange";
import SuccessStory from "layouts/successStory";
import SuccessStoryPageEdit from "layouts/successStory/SuccessStoryPageEdit";
import Support from "layouts/support";
import AddTable from "layouts/tables/AddTable";
import CreateTable from "layouts/tables/CreateTable";
import Tools from "layouts/tools";
import NewJobs from "../src/assets/images/NewJob.svg";
import TermsAndConditions from "../src/assets/images/TermsAndConditions.svg";
import ForgotPassword from "./layouts/authentication/ForgotPassword/index";
import NewPassword from "./layouts/authentication/NewPassword/index";
import ResetPassword from "./layouts/authentication/ResetPassword/index";
import VerifyEmail from "./layouts/authentication/VerifyEmail/index";
import BlogCategory from "./layouts/blogs/Category/index";
import CreateUrl from "./layouts/blogs/createurl/index";
import Notifications from "./layouts/notifications/index";
import AllUsers from "./layouts/settings/AllUsers/index";
import Tables from "./layouts/tables/index";
import Feedback from "layouts/feedback";
import SkillRequests from "layouts/skillRequests";
import ToolRequests from "layouts/toolRequests";
import PublicFeedback from "layouts/public-feedback";
const ListPost = React.lazy(() => import("./layouts/Post/ListPost/index"));
const AddPost = React.lazy(() => import("layouts/Post/AddPost"));
const PostCategory = React.lazy(() => import("layouts/Post/Postcategory"));
const SuccessStoryCategory = React.lazy(() => import("layouts/successStory/successCategory/SuccessStoryCategory"));
const AllCategories = React.lazy(() => import("layouts/Post/Postcategory/all-categories"));

const routes = [
  // Notification

  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <img src="/images/svg-icons/Dashboard.svg" style={{ width: "30px", height: "30px" }} alt="Dashbaord" />,
    route: "/dashboard",
    component: (
      <Suspense fallback={""}>
        <Dashboard />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: true,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Profiles",
    key: "Profiles",
    icon: <img src="/images/svg-icons/Profile.svg" style={{ width: "30px", height: "30px" }} alt="Profile" />,
    route: "/users?",
    component: (
      <Suspense fallback={""}>
        <UserSearch />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: true,
    sideDropdown: [
      { name: "All Users", path: "/users" },
      { name: "Users Identity Approval", path: "/users/identifydocuments" },
      { name: "Banned profiles", path: "/users/ban" },
    ],
    access: ["super_admin"],
  },
  {
    type: "collapse",
    name: "New skill",
    key: "newSkillCategory",
    icon: (
      <Icon fontSize="small">
        <HandymanIcon />
      </Icon>
    ),
    route: "/skill-categories/new",
    component: (
      <Suspense fallback={""}>
        <NewSkillCategory />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Edit skill category",
    key: "editSkillCategory",
    icon: (
      <Icon fontSize="small">
        <HandymanIcon />
      </Icon>
    ),
    route: "/skill-categories/:id/edit",
    component: (
      <Suspense fallback={""}>
        <EditSkillCategory />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },

  // skills start from here
  {
    type: "collapse",
    name: "Skills",
    key: "skills",
    icon: <img src="/images/svg-icons/Skills.svg" style={{ width: "30px", height: "30px" }} alt="Skills" />,
    route: "/skills",
    component: (
      <Suspense fallback={""}>
        <ListSkill />
      </Suspense>
    ),

    sideDropdown: [
      { name: "All Skills", path: "/skills" },
      { name: "Add New Skill", path: "/skills/new" },
      { name: "Skill Requests", path: "/skill-requests" },
    ],
    isPrivate: true,
    sidebarDisplay: true,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Skill Requests",
    key: "skill-requests",
    icon: <img src="/images/svg-icons/Skills.svg" style={{ width: "30px", height: "30px" }} alt="Skills" />,
    route: "/skill-requests",
    component: (
      <Suspense fallback={""}>
        <SkillRequests />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Tool Requests",
    key: "tool-requests",
    icon: <img src="/images/svg-icons/Skills.svg" style={{ width: "30px", height: "30px" }} alt="Skills" />,
    route: "/tool-requests",
    component: (
      <Suspense fallback={""}>
        <ToolRequests />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "New skill",
    key: "newSkill",
    icon: (
      <Icon fontSize="small">
        <ConstructionIcon />
      </Icon>
    ),
    route: "/skills/new",
    component: (
      <Suspense fallback={""}>
        <NewSkill />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Edit skill",
    key: "editSkill",
    icon: (
      <Icon fontSize="small">
        <ConstructionIcon />
      </Icon>
    ),
    route: "/skills/:id/edit",
    component: (
      <Suspense fallback={""}>
        <EditSkill />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },

  {
    type: "collapse",
    name: "Edit skill",
    key: "editSkill",
    icon: (
      <Icon fontSize="small">
        <ConstructionIcon />
      </Icon>
    ),
    route: "/skills/main-categories/:id/edit",
    component: (
      <Suspense fallback={""}>
        <EditMain />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },

  // posts

  {
    type: "collapse",
    name: "Posts",
    key: "posts",
    icon: <img src="/images/svg-icons/Post.svg" style={{ width: "30px", height: "30px" }} alt="Post" />,
    route: "/posts",
    component: (
      <Suspense fallback={""}>
        <ListPost />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: true,
    sideDropdown: [
      { name: "All Posts", path: "/posts" },
      { name: "Add New Post", path: "/posts/new" },
      { name: "All Categories", path: "/posts/categories" },
      { name: "Add Category", path: "/posts/add-category" },
    ],
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Categories",
    key: "categories",
    icon: <img src={TermsAndConditions} />,
    route: "/posts/categories",
    component: (
      <Suspense fallback={""}>
        <AllCategories />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "New Post",
    key: "Newpost",
    icon: <img src={TermsAndConditions} />,
    route: "/posts/new",
    component: (
      <Suspense fallback={""}>
        <AddPost />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "New Post Category",
    key: "NewPostCategory",
    icon: <img src={TermsAndConditions} />,
    route: "/posts/add-category",
    component: (
      <Suspense fallback={""}>
        <PostCategory />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,

    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Tables",
    key: "Tables",
    icon: <img src="/images/svg-icons/Tables.svg" style={{ width: "30px", height: "30px" }} alt="Tables" />,
    route: "/Tables",
    component: (
      <Suspense fallback={""}>
        <Tables />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: true,
    sideDropdown: [
      { name: "All Table", path: "/Tables" },
      { name: "Add Table", path: "/add-table" },
    ],
    access: ["admin", "editor", "super_admin"],
  },
  {
    route: "/add-table",
    component: (
      <Suspense fallback={""}>
        <AddTable />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: true,
    access: ["admin", "editor", "super_admin"],
  },
  {
    route: "/add-table/:id",
    component: (
      <Suspense fallback={""}>
        <CreateTable />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: true,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Blogs",
    key: "blogs",
    icon: <img src="/images/svg-icons/Blogs.svg" style={{ width: "30px", height: "30px" }} alt="Blogs" />,
    route: "/blogs",
    component: (
      <Suspense fallback={""}>
        <ListBlog />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: true,
    sideDropdown: [
      { name: "All Blogs", path: "/blogs" },
      { name: "Add New Blog", path: "/blogs/new" },
      { name: "Blog Categories", path: "/blogs/category" },
      { name: "Uploaded Images", path: "/blogs/uploadedimages" },
      { name: "Create Image  URL", path: "/blogs/imageurl" },
    ],
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Success Story",
    key: "Success_story",
    icon: <img src="/images/svg-icons/SuccessStory.svg" style={{ width: "30px", height: "30px" }} alt="SuccessStory" />,
    route: "/success-story",
    component: (
      <Suspense fallback={""}>
        <SuccessStory />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: true,
    access: ["admin", "editor", "super_admin"],
    sideDropdown: [
      { name: "All Success Stories", path: "/success-story" },
      { name: "Add Success Story", path: "/success-story/edit-page" },
      { name: "Add New Category", path: "/success-story/add-category" },
    ],
  },
  {
    type: "collapse",
    name: "Tools",
    key: "tools",
    icon: <img src="/images/svg-icons/Post.svg" style={{ width: "30px", height: "30px" }} alt="Tools" />,
    route: "/tools",
    component: (
      <Suspense fallback={""}>
        <Tools />
      </Suspense>
    ),

    sideDropdown: [
      { name: "All Tools", path: "/tools" },
      { name: "Tool Requests", path: "/tool-requests" },
    ],
    isPrivate: true,
    sidebarDisplay: true,
    access: ["admin", "editor", "super_admin"],
  },
  {
    name: "Edit Success Story",
    key: "success_story_edit",
    route: "/success-story/edit-page",
    component: (
      <Suspense fallback={""}>
        <SuccessStoryPageEdit />
      </Suspense>
    ),
    isPrivate: true,
    access: ["admin", "editor", "super_admin"],
  },
  {
    name: "Add Success Story Category",
    key: "add_success_story_category",
    route: "/success-story/add-category",
    component: (
      <Suspense fallback={""}>
        <SuccessStoryCategory />,
      </Suspense>
    ),
    isPrivate: true,
    access: ["admin", "editor", "super_admin"],
  },

  // finance
  {
    type: "collapse",
    name: "Finance",
    key: "finance",
    icon: <img src="/images/svg-icons/Finance.svg" style={{ width: "30px", height: "30px" }} alt="Finance" />,
    route: "/finance",
    component: (
      <Suspense fallback={""}>
        <FinanceStatistics />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: true,
    sideDropdown: [
      { name: "UTH Earning", path: "/finance" },
      { name: "Platform Fee", path: "/finance/platform-fee" },
      { name: "Freelancers Payout", path: "/finance/withdrawals" },
    ],
    access: ["super_admin"],
  },

  // jobs
  {
    type: "collapse",
    name: "Jobs",
    key: "jobs",
    icon: <img src="/images/svg-icons/Jobs.svg" style={{ width: "30px", height: "30px" }} alt="Jobs" />,
    route: "/jobs",
    component: (
      <Suspense fallback={""}>
        <Jobs />
      </Suspense>
    ),
    sideDropdown: [
      { name: "All Jobs", path: "/jobs" },
      { name: "All Contracts", path: "/jobs/active-contracts" },
    ],
    isPrivate: true,
    sidebarDisplay: true,
    access: ["super_admin"],
  },
  {
    // type: "collapse",
    name: "Active Contracts",
    key: "active-contracts",
    route: "/jobs/active-contracts",
    component: (
      <Suspense fallback={""}>
        <ActiveContracts />
      </Suspense>
    ),
    isPrivate: true,
    // sidebarDisplay: true,
    sidebarDisplay: false,
    access: ["super_admin"],
  },
  {
    type: "collapse",
    name: "Jobdetails",
    key: "jobdetails",
    icon: <img src={NewJobs} />,
    route: "/jobdetails",
    component: (
      <Suspense fallback={""}>
        <JobDetails />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["super_admin"],
  },

  // User Search

  // {
  //   type: "collapse",
  //   name: "User Search",
  //   key: "userSearch",
  //   icon: (
  //     <Icon fontSize="small">
  //       <PersonSearchIcon />
  //     </Icon>
  //   ),
  //   route: "/users/search",
  //   component: <UserSearch />,
  //   isPrivate: true,
  //   // sidebarDisplay: true,
  //   sidebarDisplay: false
  // },

  {
    type: "collapse",
    name: "User Profile",
    key: "userProfile",
    icon: (
      <Icon fontSize="small">
        <PersonSearchIcon />
      </Icon>
    ),
    route: "/users/:id",
    component: (
      <Suspense fallback={""}>
        <UserProfile />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["super_admin"],
  },

  // Notification

  {
    type: "collapse",
    name: "Notification",
    key: "users/notification",
    icon: <img src="/images/svg-icons/Notification.svg" style={{ width: "30px", height: "30px" }} alt="Notification" />,
    route: "/users/notification",
    component: (
      <Suspense fallback={""}>
        <Notifications />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: true,
    access: ["super_admin"],
  },

  {
    type: "collapse",
    name: "sendnotification",
    key: "sendnotification",
    icon: (
      <Icon fontSize="small">
        <PersonSearchIcon />
      </Icon>
    ),
    route: "/sendnotification/",
    component: (
      <Suspense fallback={""}>
        <SendNotification />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["super_admin"],
  },

  // Withdrawals
  {
    type: "collapse",
    name: "Withdrawals",
    key: "withdrawals",
    icon: (
      <Icon fontSize="small">
        <AttachMoneyIcon />
      </Icon>
    ),
    route: "/finance/withdrawals",
    component: (
      <Suspense fallback={""}>
        <ListWithdrawal />
      </Suspense>
    ),
    isPrivate: true,
    // sidebarDisplay: true,
    sidebarDisplay: false,
    access: ["super_admin"],
  },
  {
    type: "collapse",
    name: "Payoutdetails",
    key: "Payoutdetails",
    icon: (
      <Icon fontSize="small">
        <AttachMoneyIcon />
      </Icon>
    ),
    route: "/finance/payout/:id",
    component: (
      <Suspense fallback={""}>
        <PayoutDetails />
      </Suspense>
    ),
    isPrivate: true,
    // sidebarDisplay: true,
    sidebarDisplay: false,
    access: ["super_admin"],
  },

  {
    type: "collapse",
    name: "Withdrawal details",
    key: "withdrawalDetails",
    icon: (
      <Icon fontSize="small">
        <AttachMoneyIcon />
      </Icon>
    ),
    route: "/withdrawals/:id",
    component: (
      <Suspense fallback={""}>
        <WithdrawalDetails />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["super_admin"],
  },

  // payments

  {
    type: "collapse",
    name: "Platform Fees",
    key: "platform-fee",
    icon: (
      <Icon fontSize="small">
        <AttachMoneyIcon />
      </Icon>
    ),
    route: "/finance/platform-fee",
    component: (
      <Suspense fallback={""}>
        <PlatformFee />
      </Suspense>
    ),
    isPrivate: true,
    // sidebarDisplay: true,
    sidebarDisplay: false,
    access: ["super_admin"],
  },

  // Banned users

  {
    type: "collapse",
    name: "Banned profiles",
    key: "bannedProfiles",
    icon: (
      <Icon fontSize="small">
        <BlockIcon />
      </Icon>
    ),
    route: "/users/ban",
    component: (
      <Suspense fallback={""}>
        <ListBannedUsers />
      </Suspense>
    ),
    isPrivate: true,
    // sidebarDisplay: true,
    sidebarDisplay: false,
    access: ["super_admin"],
  },

  // Applications

  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: (
      <Icon fontSize="small">
        <GroupAddIcon />
      </Icon>
    ),
    route: "/applications",
    component: (
      <Suspense fallback={""}>
        <ListApplication />
      </Suspense>
    ),
    isPrivate: true,
    // sidebarDisplay: true,
    sidebarDisplay: false,
    access: ["super_admin"],
  },

  // Identify Documents
  {
    type: "collapse",
    name: "Identify Documents",
    key: "identifydocuments",
    icon: (
      <Icon fontSize="small">
        <GroupAddIcon />
      </Icon>
    ),
    route: "/users/identifydocuments",
    component: (
      <Suspense fallback={""}>
        <IdentifyDocuments />
      </Suspense>
    ),
    isPrivate: true,
    // sidebarDisplay: true,
    sidebarDisplay: false,
    access: ["super_admin"],
  },
  {
    type: "collapse",
    name: "Identify Documents",
    key: "identifydocuments",
    icon: (
      <Icon fontSize="small">
        <AttachMoneyIcon />
      </Icon>
    ),
    route: "/users/identifyDocuments/:id",
    component: (
      <Suspense fallback={""}>
        <IdentifyDocumentsDetails />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["super_admin"],
  },
  {
    type: "collapse",
    name: "Blogs",
    key: "blogs",
    icon: (
      <Icon fontSize="small">
        <AttachMoneyIcon />
      </Icon>
    ),
    route: "/blogs",
    component: (
      <Suspense fallback={""}>
        <ListBlog />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Blobs",
    key: "blogs",
    icon: (
      <Icon fontSize="small">
        <AttachMoneyIcon />
      </Icon>
    ),
    route: "/blogs/new",
    component: (
      <Suspense fallback={""}>
        <AddBlog />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Blobs",
    key: "blogs",
    icon: (
      <Icon fontSize="small">
        <AttachMoneyIcon />
      </Icon>
    ),
    route: "/blogs/imageurl",
    component: (
      <Suspense fallback={""}>
        <CreateUrl />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "UploadImage",
    key: "UploadImage",
    icon: (
      <Icon fontSize="small">
        <AttachMoneyIcon />
      </Icon>
    ),
    route: "/blogs/uploadedimages",
    component: (
      <Suspense fallback={""}>
        <UploadedImages />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Category",
    key: "Category",
    icon: (
      <Icon fontSize="small">
        <AttachMoneyIcon />
      </Icon>
    ),
    route: "/blogs/category",
    component: (
      <Suspense fallback={""}>
        <BlogCategory />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Blobs",
    key: "blogs",
    icon: (
      <Icon fontSize="small">
        <AttachMoneyIcon />
      </Icon>
    ),
    route: "/blogs/update/:slug/:id",
    component: (
      <Suspense fallback={""}>
        <UpdateBlog />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Application details",
    key: "applicationDetails",
    icon: (
      <Icon fontSize="small">
        <GroupAddIcon />
      </Icon>
    ),
    route: "/applications/:id",
    component: (
      <Suspense fallback={""}>
        <ApplicationDetails />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: false,
    access: ["super_admin"],
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: (
      <Suspense fallback={""}>
        <SignIn />
      </Suspense>
    ),
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">Forgot Password</Icon>,
    route: "/authentication/forgot-password",
    component: (
      <Suspense fallback={""}>
        <ForgotPassword />
      </Suspense>
    ),
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">Verify Email</Icon>,
    route: "/authentication/email-verification",
    component: (
      <Suspense fallback={""}>
        <VerifyEmail />
      </Suspense>
    ),
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">Reset Code</Icon>,
    route: "/authentication/reset-code",
    component: (
      <Suspense fallback={""}>
        <ResetPassword />
      </Suspense>
    ),
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">New Password</Icon>,
    route: "/authentication/new-password",
    component: (
      <Suspense fallback={""}>
        <NewPassword />
      </Suspense>
    ),
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },

  // settings
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <img src="/images/svg-icons/Settings.svg" style={{ width: "30px", height: "30px" }} alt="Settings" />,
    route: "/settings",
    component: (
      <Suspense fallback={""}>
        <AllUsers />
      </Suspense>
    ),
    isPrivate: true,
    sidebarDisplay: true,
    sideDropdown: [
      { name: "All users and roles", path: "/settings" },
      { name: "Add New user", path: "/settings/add-user" },
      { name: "Password Change", path: "/settings/passwordchange" },
    ],
    access: ["super_admin"],
  },
  {
    type: "collapse",
    name: "Add user",
    key: "add-user",
    icon: (
      <Icon fontSize="small">
        <AttachMoneyIcon />
      </Icon>
    ),
    route: "/settings/add-user",
    component: (
      <Suspense fallback={""}>
        <AddUser />
      </Suspense>
    ),
    isPrivate: true,
    // sidebarDisplay: true,
    sidebarDisplay: false,
    access: ["super_admin"],
  },
  {
    type: "collapse",
    name: "Edit user",
    key: "edit-user",
    icon: (
      <Icon fontSize="small">
        <AttachMoneyIcon />
      </Icon>
    ),
    route: "/settings/edit-user/:id",
    component: (
      <Suspense fallback={""}>
        <EditUser />
      </Suspense>
    ),
    isPrivate: true,
    // sidebarDisplay: true,
    sidebarDisplay: false,
    access: ["super_admin"],
  },
  {
    type: "collapse",
    name: "Change Password",
    key: "change-password",
    icon: (
      <Icon fontSize="small">
        <AttachMoneyIcon />
      </Icon>
    ),
    route: "/settings/passwordchange",
    component: (
      <Suspense fallback={""}>
        <PasswordChange />
      </Suspense>
    ),
    isPrivate: true,
    // sidebarDisplay: true,
    sidebarDisplay: false,
    access: ["admin", "editor", "super_admin"],
  },
  {
    type: "collapse",
    name: "Change Role",
    key: "change-role",
    icon: (
      <Icon fontSize="small">
        <AttachMoneyIcon />
      </Icon>
    ),
    route: "/settings/disable-user/:userId",
    component: (
      <Suspense fallback={""}>
        <DisableUser />
      </Suspense>
    ),
    isPrivate: true,
    // sidebarDisplay: true,
    sidebarDisplay: false,
    access: ["super_admin"],
  },

  {
    type: "collapse",
    name: "Feedback",
    key: "feedback",
    icon: <img src="/images/svg-icons/Support.svg" style={{ width: "30px", height: "30px" }} alt="Support" />,
    route: "/feedback",
    component: (
      <Suspense fallback={""}>
        <Feedback />
      </Suspense>
    ),
    sideDropdown: [
      {
        name: "Authenticated Feedback",
        path: "/feedback",
      },
      {
        name: "Public Feedback",
        path: "/public-feedback",
      },
    ],
    sidebarDisplay: true,
    access: ["admin", "super_admin", "editor"],
  },

  {
    type: "collapse",
    name: "Support",
    key: "support",
    icon: <img src="/images/svg-icons/Support.svg" style={{ width: "30px", height: "30px" }} alt="Support" />,
    route: "/public-feedback",
    component: (
      <Suspense fallback={""}>
        <PublicFeedback />
      </Suspense>
    ),
    sidebarDisplay: false,
    access: ["admin", "super_admin", "editor"],
  },

  {
    type: "collapse",
    name: "Support",
    key: "support",
    icon: <img src="/images/svg-icons/Support.svg" style={{ width: "30px", height: "30px" }} alt="Support" />,
    route: "/support",
    component: (
      <Suspense fallback={""}>
        <Support />
      </Suspense>
    ),
    sidebarDisplay: true,
    access: ["admin", "super_admin"],
  },

  // logout

  {
    type: "collapse",
    name: "Log out",
    key: "logout",
    icon: <img src="/images/svg-icons/Logout.svg" style={{ width: "30px", height: "30px" }} alt="Logout" />,
    route: "/logout",
    component: (
      <Suspense fallback={""}>
        <LogOut />
      </Suspense>
    ),
    sidebarDisplay: true,
    access: ["admin", "editor", "super_admin"],
  },
];

export default routes;
